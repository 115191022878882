// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

.swal2-container {
  z-index: 2000;
}
::ng-deep.swal2-container {
  z-index: 1000000;
}
.swal-overlay {
  z-index: "100000000000 !important";
}

@media print {
  body {
    overflow: auto;
  }
  .noprint {
    display: none;
  }
  .onlyPrint {
    display: block !important;
  }
  .printGrid {
    display: table !important;
  }
  .fullPage {
    width: 100%;
    height: 100vh;
    display: inline-block !important;
    page-break-inside: auto;
    page-break-after: "always";
    border: none;
    & > * > * > * {
      border: none;
    }
    :last-of-type {
      page-break-after: initial;
    }
  }
  .summaryPage {
    width: 100%;
    min-height: 100vh;
    display: inline-block !important;
    page-break-inside: auto;
    page-break-after: "always";
    border: none;
    & > * > * > * {
      border: none;
    }
  }
  .noBorder {
    border: none;
    & > * > * > * {
      border: none;
    }
  }
  .pageBreakAfter {
    page-break-after: always;
  }
  .pageBreak {
    page-break-inside: auto;
    page-break-before: always;
  }
  .printScale {
    transform: scale(0.8, 0.8);
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .printXs12 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  #forgeViewer {
    #guiviewer3d-toolbar {
      display: none;
    }
    .viewcubeWrapper {
      display: none;
    }
  }
  .MuiTypography-h5,
  .MuiTypography-body2 {
    font-size: 0.7rem;
  }

  .tableBreak {
    table {
      page-break-after: auto;
    }
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-row-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
}
@media screen {
  .onlyPrint {
    display: none !important;
  }
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
